import React, { useEffect, useState } from 'react'
import api from '../../utils/api'

const index = () => {
  const [batch, setBatch] = useState([]);
  useEffect(() => {
    const getBatch = async () => {
      const result = await api.getBatch();
      console.log(result);
      setBatch(result);
    }

    getBatch()
  }, [])

  const dateTime = (date) => {
    const d = new Date(date);
    const time = `${d.getFullYear()} ${d.getMonth()} ${d.getDate()}`;
    return time;
  }

  return (
    <div>
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Batch</th>
            <th scope="col">Start Date</th>
            <th scope="col">End Date</th>
            <th scope="col">Status Offered</th>
            <th scope="col">Program</th>
            <th scope="col">Description</th>
          </tr>
        </thead>
        <tbody>
          {batch?.map((value, key) => (
            <tr>
              <th scope="row">{key + 1}</th>
              <td>{value.batch}</td>
              <td>{dateTime(value.date_start)}</td>
              <td>{dateTime(value.date_end)}</td>
              <td>{value.status_offered_program}</td>
              <td>{value.program[0].name}</td>
              <td>{value.program[0].descriptions}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default index;
