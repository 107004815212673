import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';

// Components
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';

import Index from './Pages/Index';
import Batch from './Pages/Batch';
import Program from './Pages/Program';
import Sidebar from './Components/Sidebar';

function AdminLayout() {
  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar />
        <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <Outlet />
        </main>
      </div>
    </div>
  );
}

function App() {
  return (
    <>
      <header>
        <Navbar />
      </header>
      <main>
        <Routes>
          <Route element={<AdminLayout />} path="/">
            <Route index element={<Index />} />
            <Route path='batch' element={<Batch />} />
            <Route path='program' element={<Program />} />
          </Route>
        </Routes>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default App;
