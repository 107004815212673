import React, { useEffect, useState } from 'react';
import api from '../../utils/api';

const index = () => {
  const [sections, setSections] = useState([]);
  useEffect(() => {
    const getSections = async () => {
      const result = await api.getSectionsProgram();
      console.log(result);
      setSections(result);
    }

    getSections()
  }, []);
  return <div>
    <div class="d-flex flex-column flex-md-row p-4 gap-4 py-md-5">
      <div class="list-group">
        {sections?.map((value, key) => (
          <label key={key} class="list-group-item d-flex gap-3">
            <input class="form-check-input flex-shrink-0" type="checkbox" value="" checked={key > 6 ? false : true} style={{ fontSize: '1.375em' }} />
            <span class="pt-1 form-checked-content">
              <strong>{value.title}</strong>
              <small class="d-block text-body-secondary">
                {/* <svg class="bi me-1" width="1em" height="1em"><use xlink:href="#calendar-event"></use></svg> */}
                1:00–2:00pm
              </small>
            </span>
          </label>
        ))}
      </div>
    </div>
  </div>
};

export default index;
