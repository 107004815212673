import React from 'react';
import { Link } from 'react-router-dom';

function Sidebar() {
  return (
    <div class="sidebar border border-right col-md-3 col-lg-2 p-0 bg-body-tertiary">
      <div class="offcanvas-md offcanvas-end bg-body-tertiary" tabindex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
          <ul class="nav flex-column">
            <li class="nav-item">
              <Link to="/" class="nav-link d-flex align-items-center gap-2 active" aria-current="page">
                {/* <svg class="bi"><use xlink:href="#house-fill"></use></svg> */}
                Dashboard
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/program" class="nav-link d-flex align-items-center gap-2">
                {/* <svg class="bi"><use xlink:href="#file-earmark"></use></svg> */}
                Program
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/batch" className='nav-link d-flex align-items-center gap-2'>
                Batch
              </Link>
            </li>
            <li class="nav-item">
              <a class="nav-link d-flex align-items-center gap-2" href="#">
                {/* <svg class="bi"><use xlink:href="#people"></use></svg> */}
                Employees
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link d-flex align-items-center gap-2" href="#">
                {/* <svg class="bi"><use xlink:href="#graph-up"></use></svg> */}
                Reports
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link d-flex align-items-center gap-2" href="#">
                {/* <svg class="bi"><use xlink:href="#puzzle"></use></svg> */}
                Integrations
              </a>
            </li>
          </ul>

          <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-body-secondary text-uppercase">
            <span>Saved reports</span>
            <a class="link-secondary" href="#" aria-label="Add a new report">
              {/* <svg class="bi"><use xlink:href="#plus-circle"></use></svg> */}
            </a>
          </h6>
          <ul class="nav flex-column mb-auto">
            <li class="nav-item">
              <a class="nav-link d-flex align-items-center gap-2" href="#">
                {/* <svg class="bi"><use xlink:href="#file-earmark-text"></use></svg> */}
                Current month
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link d-flex align-items-center gap-2" href="#">
                {/* <svg class="bi"><use xlink:href="#file-earmark-text"></use></svg> */}
                Last quarter
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link d-flex align-items-center gap-2" href="#">
                {/* <svg class="bi"><use xlink:href="#file-earmark-text"></use></svg> */}
                Social engagement
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link d-flex align-items-center gap-2" href="#">
                {/* <svg class="bi"><use xlink:href="#file-earmark-text"></use></svg> */}
                Year-end sale
              </a>
            </li>
          </ul>

          <hr class="my-3" />

          <ul class="nav flex-column mb-auto">
            <li class="nav-item">
              <a class="nav-link d-flex align-items-center gap-2" href="#">
                {/* <svg class="bi"><use xlink:href="#gear-wide-connected"></use></svg> */}
                Settings
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link d-flex align-items-center gap-2" href="#">
                {/* <svg class="bi"><use xlink:href="#door-closed"></use></svg> */}
                Sign out
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
